/** @format */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-regular-svg-icons';

import SEO from '../components/SEO';
import styles from '../css/about-css-modules.module.css';
import Banner from '../components/Banner';
import Layout from '../components/layout/Layout';
import {
    Row,
    ResponsiveRow,
    Col,
    SectionWrapper,
    SectionInnerWrapper,
    CommonContentWrapper,
    CommonContentDivider,
    AlignRight,
    SectionHeader,
    SectionSubHeader,
} from '../components/Wrapper';

const NewsPage = ({ data }) => {
    return (
        <Layout>
            <SEO title={`All Ins Agency - News`} description={`All Ins Agency`} />
            <Banner heading="OUR NEWS" subHeading="IN THE NEWS" trail="News" trailLink="/news" />
            <SectionWrapper>
                <SectionInnerWrapper></SectionInnerWrapper>
            </SectionWrapper>
        </Layout>
    );
};

export default NewsPage;
